/**
 * ======================================
 * 说明： 评价维度配置文件
 * 作者： Silence
 * 文件： config.js
 * 日期： 2023/5/9 11:17
 * ======================================
 */
export const columns = [
  {
    field: "name",
    label: "名称",
    align: "left",
    width: "",
  },
  {
    field: "action",
    label: "操作",
    align: "center",
    width: "120",
  },
];

export const searchOptions = [];
