<!--
 * ======================================
 * 说明： 评价维度管理
 * 作者： Silence
 * 文件： index.vue
 * 日期： 2023/5/9 11:14
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <div>
    <Main
      class="h-full"
      ref="main"
      :is-page="false"
      :on-params="onParams"
      :columns="columns"
      :is-selection="false"
      api="/dimension/list"
    >
      <template #action>
        <el-input
          v-model="newDimension"
          style="width: 200px"
          placeholder="新评价维度"
        ></el-input
        ><el-button
          type="success"
          style="margin-left: 5px"
          @click="handleCreateDimension"
          >创建</el-button
        >
      </template>
      <template #table-item-name="{ row }">
        <el-input
          v-if="row.id === editId"
          v-model="row['name']"
          @focus="handleFocusEditRow(row['name'])"
          @blur="handleEditRow(row)"
          @keyup.enter.native="handleEditRow(row)"
        ></el-input>
        <span v-else>{{ row.name }}</span>
      </template>
      <template #table-item-action="{ row }">
        <el-button type="text" @click="handleUpdateDimension(row)"
          >修改</el-button
        >
        <el-button
          type="text"
          class="text-red-600"
          @click="handleDeleteDimension(row)"
          >删除</el-button
        >
      </template>
    </Main>
  </div>
</template>
<script>
import Main from "../components/main/index.vue";
import { columns } from "./config";
export default {
  name: "",
  components: { Main },
  props: {},
  data() {
    return {
      columns,
      newDimension: "",
      searchForm: {
        pageSize: 999,
        pageIndex: 1,
      },
      editId: null,
      editOldName: "",
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.onFind();
  },
  methods: {
    onParams() {
      return this.searchForm;
    },

    onFind() {
      this.$refs["main"].onFind();
    },

    /**
     *  @actions:  创建评价维度
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/9 11:21
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleCreateDimension() {
      if (!this.newDimension) {
        this.$message.error("请输入新评价维度");
        return;
      }
      this.$cloud
        .post("/dimension/create", { name: this.newDimension })
        .then(() => {
          this.$message.success("创建成功！");
          this.newDimension = "";
          this.onFind();
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },

    /**
     *  @actions:  修改评价维度名称
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/9 11:26
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleFocusEditRow(name) {
      this.editOldName = name;
    },

    /**
     *  @actions:  更新评价维度
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/9 11:27
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleEditRow(row) {
      this.$cloud
        .post("/dimension/edit", { id: row.id, name: row.name })
        .then(() => {
          this.onFind();
          this.editOldName = "";
          this.editId = null;
        })
        .catch((err) => {
          console.log("err", err);
          this.$message.error(err);
        });
    },

    /**
     *  @actions:  编辑评价维度
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/9 11:28
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleUpdateDimension(row) {
      // 判断是否有修改失败的数据 有就恢复回去  但是这儿 改数据有点麻烦 刷新一下
      if (this.editOldName) {
        this.editOldName = "";
        this.$refs["main"].onFind();
      }
      this.editId = row.id;
    },

    /**
     *  @actions:  删除评价维度
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/9 11:29
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleDeleteDimension(row) {
      this.$confirm("确定删除该评价维度吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$cloud.get("/dimension/delete", { id: row["id"] }).then(() => {
          this.$message.success("删除成功！");
          this.onFind();
        }).catch(err=>{
          this.$message.error(err)
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
